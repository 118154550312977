@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progress-bar{
  width: 100%;
  height: 28px;
  text-align: left;
  color: rgb(59, 130, 246);
  font-size: 0.8em;
  letter-spacing: 0;
border-radius: 15px;
box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
padding: 0 10px;
border: 1px solid #ccc;
}